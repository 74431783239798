<template>
		<!-- 弹窗-复制链接  start-->
		<van-popup v-model="shows" get-container="#app" >
			<div class="copys-link pos-r">
				<img @click="closepopup" class="offUp pos-a" src="~@/assets/img/publicIcon/off.png" alt="">
				<div class="copys-link-centent">
					<div class="titleName row">
						<div class="left-content ">
							<div class="textoverflow">
								{{popupParam.name}}
							</div>
							
						</div>
						<div>
							{{popupParam.title}}
						</div>
						
					</div>
					<div class="tow-code row">
						<img class="codeImg" src="~@/assets/img/sharing/wixinerweima.png" alt="">
						<div class="right-content">
							<div class="link-top">
								<div class="textoverflow">
									 <!-- https://discovol.co/web2/curation?id=609ion?id=609 -->
									 {{textlink}}
								</div>
								
							</div>
								<div @click="copyLink" class="copy-link jc-c ai-c">
									复制链接
								</div>
						</div>
					</div>
				</div>
			</div>
		</van-popup>
</template>

<script>
	export default {
		
		name: 'my-popup',
		data(){
			return{
				shows: false,//弹窗中的标志位
				textlink:'https://discovol.co/web2/curation?id=609ion?id=609',
				popupParam:{
					name:'',
					title:'',
				}
			}
		},
		mounted() {
			
		},
		methods:{
			// 关闭弹窗
			 closepopup(){
				 this.shows = false
			 },
			 copyLink(){
				 this.$copyText(this.textlink).then(e=>{
				 			if(e.text != '') {
				 				this.$toast.success('复制成功');
				 			}
				 	       
				 	      },
				 		   e=>{
				 			this.$toast.success('复制失败');
				 	      }
				       )	
			 }
		}
	}
</script>

<style lang="scss" scoped>
	// -------弹窗样式 开始
	.copys-link {
		width: 7rem;
		.offUp {
			top: 0.3rem;
			right: 0.4rem;
			width: 0.24rem;
			height: 0.24rem;
		}
		.copys-link-centent {
			width: 100%;
			height: 3.28rem;
			background: #FFFFFF;
			border-radius: 0.1rem;
			padding: 0.56rem 0.23rem 0.47rem 0.23rem;
			box-sizing: border-box;
			.titleName {
				font-size: 0.3rem;
				font-family: SourceHanSansCN;
				font-weight: 400;
				color: #333333;
				padding-right: 1rem;
				box-sizing: border-box;
				margin-bottom: 0.45rem;
				.left-content {
					width: 3.3rem;
				}
				
			}
			.tow-code {
				width: 100%;
				height: 1.47rem;
				.codeImg {
					width: 21%;
					height: 1.47rem;
				}
				.right-content {
					width: 5rem;
					height: 100%;
					padding-left: 0.34rem;
					padding-top: 0.1rem;
					box-sizing: border-box;
					.link-top {
						font-size: 0.24rem;
						font-family: SourceHanSansCN;
						font-weight: 400;
						color: #333333;
					}
					.copy-link {
						margin-top: 0.35rem;
						width: 2.96rem;
						height: 0.49rem;
						border-radius: 0.04rem;
						margin-left: 0.64rem;
						font-size: 0.24rem;
						font-family: SourceHanSansCN;
						font-weight: 400;
						color: #333333;
						background: url('~@/assets/img/sharing/copy.png') no-repeat 0.31rem center;
						background-size: 0.26rem 0.28rem; 
						background-color: #FDD9C0;
					}
				}
			}
		}
	}
	// -------弹窗样式 结束
</style>