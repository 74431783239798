<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>


<script>
export default {
    computed: {
        name: function() {
            return 'app';
        }
    },

	// mounted() {
	// 	window.addEventListener("popstate", function(e) {
	// 	     this.designOpen = false
	// 	   }.bind(this), false);
	// 	function bodyScale() {
	// 		      let devicewidth = document.documentElement.clientWidth;
	// 		      let scale = devicewidth / 375;  // 分母——设计稿的尺寸宽度
	// 		      document.body.style.zoom = scale;
	// 		      // document.body.style['-moz-transform'] = `scale(${scale})`;
	// 		      // document.body.style['-moz-transform-origin'] = 'center center';
			  
	// 		  };
	// 		  window.onload = window.onresize = function () {
	// 		      bodyScale();
	// 		  };
	// }
};
// import './assets/css/main.css'
// import './assets/css/color-dark.css'
</script>
<style lang="scss">
	#app {
		overflow: hidden;
		width: 100%;
		height: 100%;
		background-color: #EAEAEA;
	}
@import './assets/css/app.css';
@import './assets/css/common.scss'

</style>


