<template>
	<div class="menuSwit">
		<div class="TabsToggle">
			<van-tabs v-model="activeName"  @change="onChange">
			  <van-tab title="主页链接" name="a">
			  </van-tab>
			  <van-tab title="专栏链接" name="b">
			  </van-tab>
			  <van-tab title="推荐链接" name="c">
			  </van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
	export default {
		
		data(){
			return{
				activeName:'a'
			}
		},
		mounted() {
			
		},
		methods:{
			onChange(val){
				// console.log(this.$parentval,'-----')
			}
		}
	}
</script>

<style lang="scss" scoped>
	
	
	
</style>