<template>
	<div class="headFooter">
		<div class="headtops pos-f">
			<div class="headtop  row jc-sb">
				<div class="head-left pos-r row">
					<img @click="gotoHomeLink" src="~@/assets/img/publicIcon/headLogo.png" alt="" />
					<div class="head-left-title ai-c">用户呢称 的快捷服务平台</div>
				</div>
				<div class="head-right  column-r">
					<div class="row">
						<div @click="privateletter" class="row pos-r fast_anims"><van-badge class="pos-a potmessage jc-c ai-c" :content="1" max="99"></van-badge></div>
						<!-- <div class="Language column-r">EN</div> -->
					</div>
				</div>
			</div>
		
		</div>
		<div>
		
		</div>
		<!-- 内容部分 -->
		<div class="center pos-a" :class=" getTabsList.footer ?  getTabsList.footer ==1 : '1' ? '' :'isvipcenter'">
			<keep-alive>
				<router-view></router-view>
			</keep-alive>
		</div>
		
		<!-- 底部栏 -->
		<div class="footer  row" >
			<div class="follow column fx-4 ai-c jc-c" :class="isfoother==index ? item.className: '' " @click="footerDataChang(item.index)" v-for="(item,index) in footerData" :key="index">
				<img class="follow-son"   :src="isfoother==index ? item.imgs2 :  item.imgs" alt="" />
				<div class="follow-size" :class="isfoother==index ? item.className: '' ">{{ item.footerName }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import contentArea from './contentArea.vue';
import {mapGetters} from 'vuex';
export default {
	components: {
		contentArea
	},
	data() {
		return {
	
			activeName: sessionStorage.getItem('headnum') ? sessionStorage.getItem('headnum'): 'a',
			footerData: [
				{
					imgs: require('../../assets/img/publicIcon/share1.png'),
					imgs2: require('../../assets/img/publicIcon/share2.png'),
					footerName: '分享',
					className:'follow-size-color',
					index:0,
				},
				{
					imgs: require('../../assets/img/publicIcon/subscribe1.png'),
					imgs2: require('../../assets/img/publicIcon/subscribe2.png'),
					footerName: '订阅',
					className:'follow-size-color',
					index:1,
				},
				{
					imgs: require('../../assets/img/publicIcon/follow1.png'),
					imgs2: require('../../assets/img/publicIcon/follow2.png'),
					footerName: '关注',
					className:'follow-size-color',
					index:2,
				},
				
				
				
				{
					imgs: require('../../assets/img/publicIcon/discover1.png'),
					imgs2: require('../../assets/img/publicIcon/discover2.png'),
					footerName: '发现',
					className:'follow-size-color',
					index:3,
				},
			],
			list: [],
			loading: false,
			finished: false,
			isfoother:sessionStorage.getItem('isfoother') ? sessionStorage.getItem('isfoother') :'3',
			// Toggle: this.$store.state.menu.footerindex.footer ?  JSON.parse(sessionStorage.getItem('vuex')).leftList.footer : '1',//会员标识
				//会员标识
		};
	},
	computed:{
		...mapGetters('menu', ['getTabsList'])
	},
	mounted() {
		console.log(this.getTabsList.footer )
		
		console.log(JSON.parse(sessionStorage.getItem('vuex')).leftList.footer,'2222')	
	},
	watch:{
		getTabsList:{
			handler(newval,oldval){
				console.log(oldval,'000000')
				this.Toggle = newval
			},
			// deep:true
		}
	},
	methods: {
		// onLoad() {
		// 	// 异步更新数据
		// 	// setTimeout 仅做示例，真实场景中一般为 ajax 请求
		// 	setTimeout(() => {
		// 		for (let i = 0; i < 10; i++) {
		// 			this.list.push(this.list.length + 1);
		// 		}
				
		// 		// 加载状态结束
		// 		this.loading = false;
				
		// 		// 数据全部加载完成
		// 		if (this.list.length >= 40) {
		// 			this.finished = true;
		// 		}
		// 	}, 1000);
		// },
		privateletter(){
			this.isfoother = -1
			this.$router.push({name:'privateletter'})
		},
		// 跳转全部链接
		gotoHomeLink(){
			this.$store.dispatch('menu/RECORD_FOOTER', '2');
			// sessionStorage.setItem('Toggle', )
			this.$router.push({name:"memberHome"})
		},
	footerDataChang(val) {
			sessionStorage.setItem('isfoother', val)
			this.isfoother = val
				if(val=="0" && this.activeName=='a') {
					this.$router.push({name:'sharingHomeLink'})
				} else if(val =='0' && this.activeName=='b'){
					this.$router.push({name:'sharingColumnLink'})
				} else if(val =='0' && this.activeName=='c'){
					this.$router.push({name:'sharingReommLink'})
				} else if(val=="2") {
					this.$router.push({name:'followAllRecommAll'})
				}else if(val =="1") {
				this.$router.push({name:'subscription'})
			}else if(val =="3") {
				this.$router.push({name:'discover'})
			}
		},
		onChange(v){
			// this.$store.dispatch('menu/RECORD_FOOTER', v);
			// sessionStorage.setItem('headnum', v)
			if(this.isfoother=="0" && this.activeName=='a') {
				this.$router.push({name:'sharingHomeLink'})
			} else if(this.isfoother =='0' && this.activeName=='b'){
				this.$router.push({name:'sharingColumnLink'})
			} else if(this.isfoother =='0' && this.activeName=='c'){
				this.$router.push({name:'sharingReommLink'})
			}
			
		}
	}
};
</script>

<style lang="scss" scoped>
	::v-deep .van-tabs--line .van-tabs__wrap {
		height: 0.6rem;
	}
	::v-deep .van-tab__text--ellipsis {
		font-size: 0.26rem;
		font-family: SourceHanSansCN;
		font-weight: 400;
		// color: #333333;
	}
	::v-deep .van-tab {
		color: #333333;
	}
	::v-deep .van-tabs__line {
		width: 0.45rem;
		height: 0.03rem;
		background: #F97116;
	}
	::v-deep .van-tab--active {
		color: #F97116!important;
	}
	::v-deep .van-tabs__nav {
		background: #EAEAEA;
	}
	
.headFooter {
	width: 100%;
	height: 100%;
	// background-color: #EAEAEA;
	.headtops {
		width: 100%;
		z-index: 1;
		top: 0;
		background-color: #EAEAEA;
		.headtop {
			width: 100%;
			padding: 0.27rem 0.53rem 0.19rem 0.34rem;
			box-sizing: border-box;
			height: 0.94rem;
			
			.head-left {
				img {
					width: 0.47rem;
					height: 0.47rem;
				}

				.head-left-title {
					margin-left: 0.3rem;
					font-size: 0.24rem;
					font-family: SourceHanSansCN;
					font-weight: 400;
					color: #010101;
				}
			}
			.head-right {
				.fast_anims {
					width: 0.4rem;
					height: 0.35rem;
					// margin-right: 0.54rem;
					background: url('~@/assets/img/publicIcon/message.png') no-repeat center center;
					background-size: 100% 100%;
					
					.potmessage {
						padding: 0 0.03rem;
						font-size: 0.18rem;
						font-family: SourceHanSansCN;
						font-weight: 400;
						color: #ffffff;
						// border-radius: 50%;
						top: -0.2rem;
						right: -0.2rem;
					}
				}
				.Language {
					margin-left: 0.54rem;
					font-size: 0.3rem;
				}
			}
		}
		.menuSwit {
			width: 100%;
			height: 100%;
			.TabsToggle {
				width: 100%;
				height: 0.6rem;
			}
		}
	}
	.isvipcenter {
		top: 1.13rem!important;
	}
	.center {
		width: 100%;
		top: 0.94rem;
		overflow-y: auto;
		bottom: 1rem;
		// margin-top:1.13rem;
		margin-bottom: 0.2rem;
		box-sizing: border-box;	
		z-index: -1;
		.menuSwit {
		
			top: 0;
			width: 100%;
			height: 100%;
			.TabsToggle {
				width: 100%;
				
				height: 0.6rem;
			}
		}
	}

	// .footer :nth-child(1) {
	// 	::v-deep .follow-son{
	// 		width: 0.46rem;
	// 		height: 0.4rem;

	// 	}
	// }
	// .footer :nth-child(2) {
	// 	::v-deep .follow-son{
	// 			width: 0.46rem;
	// 			height: 0.42rem;

	// 	}
	// }
	// .footer :nth-child(3) {
	// 	::v-deep .follow-son{
	// 			width: 0.32rem!important;
	// 			height: 0.38rem!important;

	// 	}
	// }
	// .footer :nth-child(4) {
	// 	::v-deep .follow-son{
	// 		width: 0.39rem!important;
	// 		height: 0.39rem!important;

	// 	}
	// }
	.follow-size-color {
		color: #F97116!important;
	}
	.footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 0.99rem;
		background: #ffffff;
		box-shadow: 0px -4px 8px 0px rgba(221, 221, 221, 0.45);
		margin-top: 0.05rem;
		font-size: 0.2rem;
		font-family: SourceHanSansCN;
		font-weight: 500;
		color: #666666;
		z-index: 1;
		.follow-son {
			width: 0.39rem !important;
			height: 0.39rem !important;
		}
		.follow-size {
			margin-top: 0.05rem;
			font-size: 0.2rem;
			font-family: SourceHanSansCN;
			font-weight: 500;
			color: #666666;
		}
		.follow-size-color {
			color: #F97116!important;
		}
	}
}
</style>
