import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/common.scss'
import '@/assets/css/comnav.scss'

import axios from 'axios'
import '@/assets/js/flexible.js';
import '@/utils/component'
// import 'amfe-flexible';
// import 'lib-flexible'
import VueClipBoard from 'vue-clipboard2'//复制
import { Overlay } from 'vant';

// import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'
  import 'swiper/dist/js/swiper.min'
// 所有环境均使用

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 1. 引入Vant
import Vant from 'vant'

// 2. 引入组件样式
import 'vant/lib/index.css'
// 引入 Vconsole
import Vconsole from 'vconsole'
import { Toast } from 'vant';



Vue.use(Toast)
Vue.use(Overlay);
new Vconsole()
Vue.use(ElementUI);
Vue.use(Vant)
Vue.use(VueClipBoard)
// Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.prototype.axios = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
