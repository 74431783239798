const state = {
	footerindex:{
		footer:''
	}
}

const getters = {
	// getTabsList:state=>state.footerindex,
	getTabsList: (state) => {
		 console.log(state,'=====>')
	      return state.footerindex
	    }
}

// mutations
const mutations = {
	RECORD_FOOTER(state,v){
		// console.log(v,'------111,')
		state.footerindex.footer = v//数据添加
	},
	DEL_TAGS(state,i){
		state.tagsList.splice(i,1)
	}
}
// actions
// 触发方式： 
// this.$store.dispatch('actions方法名', 具体值)        // 不分模块
// this.$store.dispatch('模块名/actions方法名', 具体值) // 分模块
// mapActions调用方式
// import { mapActions } from 'vuex'
// export default {
//     computed: {
//         // 不分模块
//         ...mapActions(['actions方法名'])          
  
//         // 分模块，不改方法名
//         ...mapActions('模块名', ['actions方法名'])
         
//         // 分模块，不改方法名
//         ...mapActions('模块名',{'新actions方法名': '旧actions方法名'})
//     }
// }
const actions = {
	// 添加fotter下标
	RECORD_FOOTER({commit},data) {
		// console.log(data,'------,')
		commit('RECORD_FOOTER',data)
	},
	DEL_TAGS({commit},i) {
		commit('DEL_TAGS',i)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
