import Vue from 'vue'
import Vuex from 'vuex'
import menu from './menu/menu'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
	   menu,
  },
    plugins: [createPersistedState({
      storage: window.sessionStorage,
      reducer(val) {
        console.log(val)
        return {
          // 只储存state中的includes的leftList数据【这里多了一层includes，是因为本项目原因，大家可以打印一下val根据自己项目情况来赋值】
          leftList: val.menu.footerindex
        }
      }
    })
  ]
})
