import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/headFooter.vue'
import Layouttow from '@/views/layout/headFootertow.vue'
Vue.use(VueRouter)
// 全局路由
const globalRoutes = [
	// {
	//   path: '/',
	//   name: 'home',
	//   component: HomeView
	// },
	// {
	//   path: '/about',
	//   name: 'about',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	// }
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/login.vue'),
		meta: {
			title: '登录',
			icon: 'login'
		}
	},
	// 会员进入二级页面
	{
		path: '/sharing',
		name: 'sharing',
		 // 会员-二级页面
		component: () => import('@/views/layout/headFootertow.vue'),
		meta: {
			title: '会员进入二级页面',
			icon: 'login'
		},
		redirect: {
			name: 'memberHome'
		},
		children: [
			// 主页链接-全部推荐
			{
				path: '/sharing/memberHome',
				name: 'memberHome',
				component: () => import('@/views/sharing/memberHome/memberHome.vue'),
				meta: {
					title: '主页连接-会员&非会员登录-查看主页',
				}
			}, 
			// 主页链接-全部推荐页-下的详情页
			{
				path: '/sharing/allHomeLink/mendedDetails',
				name: 'mendedDetails',
				component: () => import('@/views/sharing/memberHome/allHomeLink/mendedDetails.vue'),
				meta: {
					title: '主页连接-会员&非会员登录-推荐内容-展现页',
				}
			}, 
			
			// 主页链接-专栏推荐页-二级页面
			{
				path: '/sharing/memberHome/memberDetail',
				name: 'columnLinkDetail',
				component: () => import('@/views/sharing/memberHome/columnLinkDetail/memberDetail.vue'),
				meta: {
					title: '主页连接-非会员查看-专栏详情页-详情页',
				}
			}, 
			
			
			// 专栏链接-专栏链接-详情列表-详情展现页-三级页面
			{
				path: '/sharing/memberHome/homeLinkUnfold',
				name: 'homeLinkUnfold',
				component: () => import('@/views/sharing/homeLink/homeLinkUnfold.vue'),
				meta: {
					title: '主页连接-非会员查看-专栏-推荐内容-展现页',
				}
			},
			// 专栏链接-专栏下的展现页-二级页面
			{
				path: '/sharing/columnLink/columnLinkDetail',
				name: 'columnDetail',
				component: () => import('@/views/sharing/columnLink/columnLinkDetail.vue'),
				meta: {
					title: '主页连接-非会员查看-专栏详情页-详情页',
				}
			}, 
			// 专栏链接-专栏链接-详情列表-详情展现页
			{
				path: '/sharing/columnLink/homeLinkUnfold',
				name: 'columnUnfold',
				component: () => import('@/views/sharing/columnLink/columnLinkUnfold.vue'),
				meta: {
					title: '主页连接-非会员查看-专栏-推荐内容-展现页',
				}
			},
			// 推荐连接-推荐内容展现页
			{
				path: '/sharing/recommLinkUnfold',
				name: 'accessKeyLink',
				component: () => import('@/views/sharing/recommLink/recommLinkUnfold.vue'),
				meta: {
					title: '推荐连接-非会员查看-推荐内容展现页',
				}
			}, 
			
			{
				path: '/followAllRecommAll',
				name: 'followAllRecommAll',
				component: () => import('@/views/follow/recommAll/recommAll.vue'),
				meta: {
					title: '关注',
				}
			}, 
			{
				path: '/subscription',
				name: 'subscription',
				component: () => import('@/views/subscription/subscription.vue'),
				meta: {
					title: '订阅',
				}
			}, {
				path: '/discover',
				name: 'discover',
				component: () => import('@/views/discover/discover.vue'),
				meta: {
					title: '发现',
				}
			}, {
				path: '/privateletter',
				name: 'privateletter',
				component: () => import('@/views/privateletter/privateletter.vue'),
				meta: {
					title: '私信',
				}
			},  {
				path: '/recordsprivate',
				name: 'recordsprivate',
				component: () => import('@/views/privateletter/recordsprivate.vue'),
				meta: {
					title: '私信往来',
				}
			}, 
			
		]
	},
// 非会员-主页链接---进入全部推荐和专推荐页得访问秘钥
	{
		path: '/nonSharing',
		name: 'nonSharing',
		redirect: {
			name: 'nomemberAccessKey'
		},
		// 非会员二级页面
		component: () => import('@/views/layout/headFooterThree.vue'),
		meta: {
			title: '会员进入二级页面',
			icon: 'login'
		},
		children: [
			
			// 非会员-主页进入全部推荐和专列推荐页得访问秘钥
			{
				path: '/nonSharing/memberAccessKey',
				name: 'nomemberAccessKey',
				component: () => import('@/views/nonSharing/homeLink/memberAccessKey.vue'),
				meta: {
					title: '主页连接-会员&非会员登录-访问秘钥',
				}
			}, 
			// 主页链接-全部推荐
			{
				path: '/nonSharing/memberHome',
				name: 'nomemberHome',
				component: () => import('@/views/nonSharing/homeLink/memberHome.vue'),
				meta: {
					title: '主页连接-会员&非会员登录-查看主页',
				}
			}, 
			// 主页链接-全部推荐页-下的详情页
			{
				path: '/nonSharing/allHomeLink/mendedDetails',
				name: 'nomendedDetails',
				component: () => import('@/views/nonSharing/homeLink/mendedDetails.vue'),
				meta: {
					title: '主页连接-会员&非会员登录-推荐内容-展现页',
				}
			}, 
			// 主页链接-专栏推荐页-二级页面
			{
				path: '/nonSharing/columnLinkDetail/memberDetail',
				name: 'nocolumnLinkDetail',
				component: () => import('@/views/nonSharing/homeLink/columnLinkDetail/memberDetail.vue'),
				meta: {
					title: '主页连接-非会员查看-专栏详情页-详情页',
				}
			}, 
			// 专栏链接-专栏链接-详情列表-详情展现页-三级页面
			{
				path: '/nonSharing/columnLinkDetail/homeLinkUnfold',
				name: 'nohomeLinkUnfold',
				component: () => import('@/views/nonSharing/homeLink/columnLinkDetail/homeLinkUnfold.vue'),
				meta: {
					title: '主页连接-非会员查看-专栏-推荐内容-展现页',
				}
			},
			//专栏 ---非会员访问秘钥
			{
				path: '/nonSharing/recommLink/accessKeyLink',
				name: 'norecaccessKeyLink',
				component: () => import('@/views/nonSharing/recommLink/accessKeyLink.vue'),
				meta: {
					title: '专栏连接-非会员查看-访问秘钥验证页',
				}
			}, 
			
			
			
			// 推荐连接-非会员查看-访问秘钥验证页
			{
				path: '/nonSharing/nonAccessKeyLink',
				name: 'noaccessKeyLink',
				component: () => import('@/views/nonSharing/columnLink/nonAccessKeyLink.vue'),
				meta: {
					title: '推荐连接-非会员查看-访问秘钥验证页',
				}
			}, 
			// 推荐连接-推荐内容展现页
			{
				path: '/nonSharing/recommLinkdetail',
				name: 'recommLinkdetail',
				component: () => import('@/views/nonSharing/recommLink/recommLinkUnfold.vue'),
				meta: {
					title: '推荐连接-非会员查看-推荐内容展现页',
				}
			}, 
			
			
		]
	}
	
	
]
// layout子路由
const mainRoutes = {
	path: '/',
	// 会员一级页面
	component: () => import('@/views/layout/headFooter.vue'),
	// component: Layout,
	name: 'login',
	redirect: {
		name: 'login'
	},
	meta: {
		title: '首页'
	},
	children: [{
		path: '/sharingHomeLink',
		name: 'sharingHomeLink',
		component: () => import('@/views/sharing/homeLink/homeLink.vue'),
		meta: {
			title: '分享-主页链接',
		}
	}, 
	{
		path: '/sharingColumnLink',
		name: 'sharingColumnLink',
		component: () => import('@/views/sharing/columnLink/columnLink.vue'),
		meta: {
			title: '分享-专栏链接',
		}
	}, 
	{
		path: '/sharingRecommLink',
		name: 'sharingReommLink',
		component: () => import('@/views/sharing/recommLink/recommLink.vue'),
		meta: {
			title: '分享-推荐链接',
		}
	}, 
	
	],
	
	
}
//保存原型对象的Push
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
//重写push方法
VueRouter.prototype.push = function(location, res, rej) {
	if (res && rej) {
		originPush.call(this, location, res, rej)
	} else {
		originPush.call(this, location, () => {}, () => {})
	}

}
//重写replace方法
VueRouter.prototype.replace = function(location, res, rej) {
	if (res && rej) {
		originReplace.call(this, location, res, rej)
	} else {
		originReplace.call(this, location, () => {}, () => {})
	}
}
const router = new VueRouter({
	base: '/',
	mode: 'history', //路由模式 哈希
	routes: globalRoutes.concat(mainRoutes)
})

export default router
